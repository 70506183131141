<template>
    <div>
        <the-app-bar/>
        
        <provider/>
        <get-started/>
        <Footer/>
    </div>
</template>

<script>
import Footer from '../../components/footer.vue'
import TheAppBar from '../../components/NavBar/TheAppBar.vue'
import GetStarted from '../../components/provider/getStarted.vue'
import Provider from '../../components/provider/provider.vue'
    export default {
  components: { TheAppBar, Footer, Provider, GetStarted },
        

      }
</script>

<style lang="scss" scoped>

</style>