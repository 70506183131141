<template>
  <!-- Register section -->
  <div class="bg-main">
    <div class="container">
      <div class="row my-10 px-4 pt-4">
        <div class="col-md-6 p-4">
          <h4>Get Started</h4>
          <div class="form-group form-row">
            <div class="col">
              <label for="Select Country"> First Name</label>
              <input
                type="text"
                v-model="contactfirstname"
                class="form-control"
                placeholder="First name"
              />
            </div>
            <div class="col">
              <label for="Select Country"> Last Name</label>
              <input
                type="text"
                v-model="contactlastname"
                class="form-control"
                placeholder="Last name"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="Select Country"> Email</label>
            <input
              type="text"
              v-model="contactemailaddress"
              class="form-control"
              placeholder="Email"
            />
          </div>
          <div class="form-group form-row">
            <div class="col">
              <label for="Select Country"> Mobile Number</label>
              <input
                type="button"
                data-toggle="modal"
                data-target="#clientContact"
                class="form-control text-left"
                :value="contactPhoneNumber"
                placeholder="Mobile Number"
              />
            </div>

            <!-- Modal -->
            <div
              class="modal fade"
              id="clientContact"
              tabindex="-1"
              role="dialog"
              aria-labelledby="clientContactLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="clientContactLabel">
                      Verify Contact Number
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div v-if="sent_code == false" class="form-group form-row">
                      <div class="col-md-4">
                        <select
                          v-model="selected_code"
                          name="business address"
                          class="form-control"
                        >
                          <option
                            v-for="code in countries"
                            :key="code.index"
                            :value="code.code"
                          >
                            {{ code.code }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-8">
                        <input
                          v-model="contactPhoneNumber"
                          type="text"
                          class="form-control"
                          placeholder="Contact Phone Number"
                        />
                      </div>
                    </div>

                    <div v-if="sent_code == true" class="form-group form-row">
                      <div class="col-md-12">
                        <input
                          v-model="OTP"
                          type="text"
                          class="form-control"
                          placeholder="Verification code"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      @click="processVerification"
                      type="button"
                      class="btn btn-primary"
                       
                    >
                      {{ sent_code == false ? "Send Code" : "Verify" }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="requiredData.item2" class="form-group">
            <label for="Select Country">How did you hear about us?</label>
            <select
              v-model="campaignchannelid"
              name="campaignchannel"
              class="form-control"
            >
              <option
                v-for="channel in requiredData.item2"
                :key="channel.index"
                :value="channel.id"
                >{{ channel.fullName }}</option
              >
            </select>
          </div>
        </div>
        <div class="col-md-6 p-4">
          <h4>Business Information</h4>
          <div class="form-group">
            <label for="Business Name">Business Name</label>
            <input
              type="text"
              v-model="businessname"
              class="form-control"
              placeholder="Enter Business Name"
            />
          </div>

          <div v-if="requiredData.item3" class="form-group">
            <label for="Select Country">Business Type</label>
            <select
              v-model="businesstypeid"
              name="country"
              class="form-control"
            >
              <option
                v-for="bus in requiredData.item3"
                :key="bus.id"
                :value="bus.id"
                >{{ bus.fullName }}</option
              >
            </select>
          </div>

          <div class="form-group">
            <label for="Busness Email">Business Email</label>
            <input
              v-model="businessemailaddress"
              type="text"
              class="form-control"
              placeholder="Email"
            />
          </div>

          <!-- <label for="Bussiness number">Business number</label>
          <input
            type="button"
            data-toggle="modal"
            data-target="#clientContact"
            :value="businessphonenumber"
            class="form-control text-left"
          /> -->

          <div class="form-group form-row">
            <div class="col-md-4">
              <label for="Bussiness number">Select country code</label>
              <select
                v-model="bus_selected_code"
                name="business address"
                class="form-control"
              >
                <option
                  v-for="code in countries"
                  :key="code.index"
                  :value="code.code"
                >
                  {{ code.code }}
                </option>
              </select>
            </div>
            <div class="col-md-8">
              <label for="Bussiness number">Business number</label>
              <input
                type="number"
                class="form-control"
                v-model="businessphonenumber"
                placeholder="Mobile Number"
              />
            </div>
          </div>

          <div class="form-group">
            <label for="countries">Select your country</label>
            <select
              class="form-control"
              @change="updateCountry"
              v-model="countryObject"
              name="countries"
            >
              <option v-for="cty in all_countries" :key="cty.id" :value="cty">{{
                cty.fullName
              }}</option>
            </select>
          </div>

          <div class="form-group">
            <label for="Busness Email">Enter Busines Location</label>
            <input
              ref="autocomplete"
              type="text"
              :id="id"
              v-model="autocompleteText"
              class="form-control"
              placeholder="Enter Business Location"
            />
          </div>

          <div class="form-group form-row">
            <div class="col">
              <label for="search text-">City</label>
              <input
                type="text"
                placeholder="Cape Coast"
                v-model="addressData.city"
                class="form-control"
              />
            </div>
            <div class="col">
              <label for="search text-">Street Address</label>
              <input
                type="text"
                placeholder="chaple street, abura"
                class="form-control"
                v-model="address"
              />
            </div>
          </div>

          <div class="form-group">
            <label for="Busness Email">Business Website</label>
            <input
              type="text"
              v-model="businesswebsite"
              class="form-control"
              placeholder="Business website"
            />
          </div>

          <div class="form-group">
            <label for="Busness Email">Business Registeration Number</label>
            <input
              type="text"
              v-model="businessregistrationnumber"
              class="form-control"
              placeholder="Business Registeration Number"
            />
          </div>

          <div class="form-group">
            <label for="Busness Email">Number of Employees</label>
            <input
              type="text"
              class="form-control"
              v-model="businessnumberofemployees"
              placeholder="Enter Number of Employees"
            />
          </div>
        </div>

        <div class="col-md-12 mb-10 text-center">
          <button @click.prevent="postData" class="btn btn-warning  px-5">
            Sign Up
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import axios from "axios";
/* eslint-disable */

const ADDRESS_COMPONENTS = {
  subpremise: "short_name",
  street_number: "short_name",
  route: "long_name",
  sublocality_level_1: "long_name",
  locality: "long_name",
  administrative_area_level_1: "short_name",
  administrative_area_level_2: "long_name",
  country: "long_name",
  postal_code: "short_name",
};

// const CITIES_TYPE = ["locality", "administrative_area_level_3"];
// const REGIONS_TYPE = [
//   "locality",
//   "sublocality",
//   "postal_code",
//   "country",
//   "administrative_area_level_1",
//   "administrative_area_level_2",
// ];

export default {
  data() {
    return {
      contactfirstname: "",
      contactlastname: "",
      contactemailaddress: "",
      contactPhoneNumber: "",
      campaignchannelid: "",
      businessname: "",
      businesstypeid: "",
      businessemailaddress: "",
      businessphonenumber: "",
      businesslocation: {
        city: "",
        streetaddress: "",
        Latitude: "",
        Longitude: "",
      },
      id: "this-component",
      address: "",
      autocomplete: null,
      autocompleteText: "",
      geolocation: {
        geocoder: null,
        loc: null,
        position: null,
      },
      addressData: {
        streetNumber: 0,
        street: "",
        suburb: "",
        city: "",
        province: "",
        country: "",
        postalCode: "",
        latitude: "",
        longitude: "",
      },
      bus_selected_code: "",
      countryObject: {},
      businesswebsite: "",
      businessregistrationnumber: "",
      businessnumberofemployees: "",
      requiredData: {},
      selected_code: "",
      countries: [
        {
          code: "+233",
          name: "Ghana",
        },
        {
          code: "+234",
          name: "Nigeria",
        },
      ],
      sent_code: false,
      OTP: "",
    };
  },

  watch: {
    autocompleteText: function(newVal, oldVal) {
      this.$emit("inputChange", { newVal, oldVal }, this.id);
    },
    country: function(newVal, oldVal) {
      this.autocomplete.setComponentRestrictions({
        country: this.country === null ? [] : this.country,
      });
    },
  },

  mounted() {
    this.getRequiredData();

    const options = {};

    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById(this.id),
      options
    );

    this.autocomplete.addListener("place_changed", this.onPlaceChanged);
    this.getCountry();
  },

  methods: {
    onPlaceChanged() {
      let place = this.autocomplete.getPlace();

      if (!place.geometry) {
        this.$emit("no-results-found", place, this.id);
        return;
      }
      if (place.address_components !== undefined) {
        this.processFormattedResults(this.formatResult(place));
      }
    },

    formatResult(place) {
      let returnData = {};

      for (let i = 0; i < place.address_components.length; i++) {
        let addressType = place.address_components[i].types[0];

        if (ADDRESS_COMPONENTS[addressType]) {
          let val =
            place.address_components[i][ADDRESS_COMPONENTS[addressType]];
          returnData[addressType] = val;
        }
      }

      returnData["latitude"] = place.geometry.location.lat();
      returnData["longitude"] = place.geometry.location.lng();

      this.$store.dispatch(
        "healthhub/Setlat",
        (returnData["latitude"] = place.geometry.location.lat())
      );
      this.$store.dispatch(
        "healthhub/Setlong",
        (returnData["longitude"] = place.geometry.location.lng())
      );

      return returnData;
    },

    updateCountry() {
      this.country = this.countryObject.isO2Code.toLowerCase().toLowerCase();
      this.$store.dispatch("healthhub/CountryId", this.countryObject.id);
    },

    processFormattedResults(formattedRes) {
      this.addressData.streetNumber = formattedRes.street_number;
      this.address = formattedRes.address;
      this.addressData.latitude = formattedRes.latitude;
      this.addressData.longitude = formattedRes.longitude;
      this.addressData.street = formattedRes.route;
      this.addressData.suburb = formattedRes.sublocality_level_1;
      this.addressData.city = formattedRes.locality;
      this.addressData.province = formattedRes.administrative_area_level_1;
      this.addressData.country = formattedRes.country;
      this.addressData.postalCode = formattedRes.postal_code;

      this.$store.dispatch("healthhub/setCity", this.addressData.city);

      this.getAddressFrom(formattedRes.latitude, formattedRes.longitude);
    },

    getCurrentLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.getAddressFrom(
              position.coords.latitude,
              position.coords.longitude
            );
            this.$store.dispatch("healthhub/Setlat", position.coords.latitude);
            this.$store.dispatch(
              "healthhub/Setlong",
              position.coords.longitude
            );
          },
          (error) => {
            this.$toasted.error(
              "Locator is unable to find your address. Please search form the search box",
              {
                duration: 5000,
              }
            );
          }
        );
      } else {
        this.$toasted.error("Your browser doesnt support geolocation API", {
          duration: 5000,
        });
      }
    },

    getAddressFrom(lat, long) {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyABmW5XJTsyoq8WrCQlXzZbyrHgyiJziXo`
        )
        .then((response) => {
          if (response.data.error_message) {
            this.$toasted.success(response.data.error_message, {
              duration: 5000,
            });
          } else {
            this.address = response.data.results
              ? response.data.results[0].formatted_address
              : response.data.results[2].formatted_address;
            this.addressData.city = response.data.results.slice(
              -2
            )[0].address_components[0].long_name;
            this.$store.dispatch("healthhub/StreetAddress", this.address);
            this.$store.dispatch("healthhub/setCity", this.addressData.city);
          }
        });
    },

    getRequiredData() {
      this.$store
        .dispatch("healthhub/getHandShakeRequiredData")
        .then((response) => {
          this.requiredData = response.data;
        });
    },

    processVerification() {
      if (this.sent_code == false) {
        let newPhone = this.contactPhoneNumber;
        if (newPhone.charAt(0) == 0 || newPhone.charAt(0) == 1) {
          var phone = newPhone.substring(1);
        }
        let main_number = `${this.selected_code}${phone}`;

        const data = {
          code: this.selected_code,
          phonenumber: main_number,
        };

        this.$store
          .dispatch("pharmacy/VerifyCustomerPhone", data)
          .then((response) => {
            if (response.status == 200) {
              this.sent_code = true;
              this.contactPhoneNumber = main_number;
            }
          });
      } else {
        this.$store
          .dispatch("pharmacy/VerifyBusinessPhone", this.OTP)
          .then((response) => {
            if (response.status == 200) {
              console.log(response.data)
              localStorage.setItem("businessPhoneNumberGUID", response.data);
              this.sent_code = true;
            }
          });
      }
    },

    getCountry: function() {
      this.$store.dispatch("dashboard/GetAllCountries");
    },

    postData() {
      let busPhone = this.businessphonenumber;
      if (busPhone.charAt(0) == 0 || busPhone.charAt(0) == 1) {
        var phone = busPhone.substring(1);
      }
      let busnumber = `${this.bus_selected_code}${phone}`;

      const formData = {
        contactfirstname: this.contactfirstname,
        contactlastname: this.contactlastname,
        contactemailaddress: this.contactemailaddress,
        contactPhoneNumber: this.contactPhoneNumber,
        campaignchannelid: this.campaignchannelid,
        businessname: this.businessname,
        businesstypeid: this.businesstypeid,
        businessemailaddress: this.businessemailaddress,
        businessphonenumber: busnumber,
        businesslocation: {
          city: this.addressData.city,
          streetaddress: this.address,
          Latitude: this.addressData.latitude,
          Longitude: this.addressData.longitude,
          countryId: this.countryObject.id,
        },
        businesswebsite: this.businesswebsite,
        businessregistrationnumber: this.businessregistrationnumber,
        businessnumberofemployees: this.businessnumberofemployees,
      };

      this.$store
        .dispatch("crowdhubaccount/MerchantHandshake", formData)
        .then((response) => {
          if (response.status == 200) {
            this.$toasted.success("Data has submited successfuly", {
              duration: 5000,
            });
            this.$router.push("/thank-you");
          }
        })
        .catch((err) => {
          this.$toasted.error("An Error has occured", {
            duration: 5000,
          });
        });
    },
  },

  computed: {
    ...mapGetters("dashboard", ["all_countries"]),
  },
};
</script>

<style scoped>
.bg-main {
  background: #f3f3f3;
}
</style>
