<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center  py-20">
          <h3 class="text-3xl fontsemibold">
            Join The Oonpay Provider Network
          </h3>
          <p class="text-xl">
            Thanks for you interest in Oonpay
          </p>
        </div>

        <div class="col-md-6 pt-20">
          <h3 class="text-2x font-semibold ">
            Create Access to a reliable quality healthcare for everyone in your
            community
          </h3>
          <p class="text-lg">
            Oonpay takes care of all your invoices, bills payment this includes
            Health Care Providers Get Paid Remotely, from anywhere in the world
          </p>
           <button class="btn btn-warning  px-5">Join us Today</button>
        </div>

        <div class="col-md-6">
          <img src="../../assets/images/updates/oonpay_business.jpeg" alt="" />
        </div>

        <div class="col-md-12 text-center">
          <h3 class="text-xl fontsemibold">Health Care Providers Benefits</h3>
        </div>
        <div class="col-md-4 p-8">
          <img src="../../assets/images/inner-img/code_qr_qr code.png" class="px-28" alt="" />
          <p class="font-montserrat  font-semibold pt-3 text-center ">
            On-Site QR-Code Instant Payment
          </p>
          <p class="text-center px-3">
            Onsite QR-Code Instant Payment Processing, i.e "addmision fees"
          </p>
        </div>
        <div class="col-md-4 p-8">
          <img src="../../assets/images/inner-img/cart_icon.png" class="px-28" alt="" />
          <p class="font-montserrat  font-semibold pt-3 text-center">
            Share Medical Invoice To Local and International Benefactor
          </p>
          <p class="text-center px-3">
            Receive payment for your invoices instantly by sharing oonpay
            #paytag with global and local benefactors
          </p>
        </div>
        <div class="col-md-4 p-8">
          <img src="../../assets/images/inner-img/network_organization.png" class="px-28" alt="" />
          <p class="font-montserrat  font-semibold pt-3 text-center ">
            Grow Your Practice Via Health Care Pre-Paid Packs
          </p>
          <p class="text-center px-3">
            Create Pre-Paid Health Care Packs Services and access the vast pool
            of potential clients via the oonpay network
          </p>
        </div>
        <!-- Carousel here -->

        
      </div>
    </div>

    
  </div>
</template>

<script>

export default {

};
</script>

<style scoped>
.img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
